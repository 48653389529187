<template>
	<div class="title">
		<div class="title-logo">
			<img src="@/assets/image/logo.png">
			<span>王者兼职</span>
		</div>
		<div class="title-left">
			<span>
				<router-link to="./" class="tabItem" exact>首页</router-link>
			</span>
			<span v-for="(item,index) in industryInfos" :key="index"  class="tabItem"
			@click="hang(index,item.industryId)">
				{{item.industryName}}
			</span>
			<span>
				<router-link to="./pj-login" class="tabItem ">企业服务</router-link>
			</span>
			<span>
				<router-link to="./pj-about" class="tabItem">关于我们</router-link>
			</span>
		</div>
		<div class="title-right">
			
			<div class="title-right-login" @click="gologin" v-if="!type">
				
			<img src="@/assets/image/login.png" >
			<span>未登录，请注册</span>
			
			</div>
			<div class="title-right-login" v-if="type==0">
				
			<img src="@/assets/image/login3.png" >
			<el-dropdown @command="handleCommand">
						<span class="el-dropdown-link">
							欢迎您，求职者<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" style="width: 1.5rem;height: .4rem;">
							<el-dropdown-item style="font-size: .12rem;width: 1rem;height: .4rem;font-family: PingFang SC;
			font-weight: 400;text-align: center;" command="a">退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
			
			</div>
			<div class="title-right-login" v-if="type==1">
				
			<img src="../../assets/image/login2.png" >
			<el-dropdown @command="handleCommand">
						<span class="el-dropdown-link">
							欢迎您，招聘者<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" style="width: 1.5rem;height: .4rem;">
							<el-dropdown-item style="font-size: .12rem;width: 1rem;height: .4rem;font-family: PingFang SC;
			font-weight: 400;text-align: center;" command="a">退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
			
			</div>
			
		</div>
		<div class="sjd-list">
			<img src="../../assets/image/sjd.png" alt="" class="sjd">
			<div class="erweima-list">
				<div class="erweima-item">
					<img src="../../assets/image/erweima1.png" alt="">
					<p>下载用户版客户端</p>
				</div>
				<div class="erweima-item">
					<img src="../../assets/image/erweima2.png" alt="">
					<p>下载商户版客户端</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../../common/api/axios.js'
	import {
		logout,industryInfo
	} from '../../common/api/api.js'
	export default {
		data() {
			return {
				type:'',
				token: '',
				name: '',
				industryInfos:[],
			}
		},
		methods: {
			hang(index, id) {
				this.$router.push({path:'/pj-information',query: {value:id,currcet:index}})
			},
			handleCommand(command) {
				if(command=='a'){
					this.out()
				}
				
			},
			gologin(){
				this.$router.push('./pj-login')
			},
			out() {
				let that = this
				this.$store.dispatch('initDic').then(() => {
					$http.request({
						url: logout,
					}).then((res) => {
						if (res.code == 200) {
							localStorage.removeItem('token');
							localStorage.removeItem('type');
							that.token = ''
							this.$message({
								message: '退出成功!',
								type: 'success',
								duration:'2000'
							});
							this.$router.push('./')
						}
					}).catch(() => {

					})
				})
			}

		},
		created() {
			let that = this
			this.$store.dispatch('initDic').then(() => {
				$http.request({
					url: industryInfo,
				}).then((res) => {
					that.industryInfos = res.data.industryInfos
				}).catch(() => {
					
				})
			})
			this.token = localStorage.getItem("token")
			this.type = localStorage.getItem("type")
		}
	}
</script>

<style scoped>
	.title-right-login{
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.title-right-login img{
		width: .39rem;
	}
	.title-right-login span{
		margin-left: .12rem;
		font-size:.14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
	}
	.title-right{
		margin-left: .65rem;
	}
	.title-left span {
		margin-left: .64rem;
	}
	.tabItem {
		padding: 10px 0;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 600;
		color: #333333;
		text-decoration:none
	}
	.router-link-active {
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: bold;
		color:#08C8BD;
		border-bottom: .04rem solid #08C8BD;
	}

	.title {
		display: flex;
		align-items: center;
		width: 100%;
		height: .72rem;
		z-index: 999;
		font-size: .16rem;
		font-family: PingFang SC;
		color: #FFFFFF;
		cursor: pointer;
		box-shadow: 0px 3px 6px rgba(172, 172, 172, 0.1);
		transform: translate3d(0, 0, 0);
	}
	.title-logo {
		margin-left: 2.3rem;
		display: flex;
		align-items: center;
	}
	
	.title-logo img {
		width: 0.56rem;
		margin-right: 0.2rem;
	}
	.title-logo span {
		font-size: 0.32rem;
		font-weight: normal;
		color: #333;
	}
	.active {
		padding: 10px 0;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 600;
		border-bottom: 3px solid #08C8BD;
		color: #08C8BD !important;
	}
	.sjd-list {
		position: relative;
		margin-left: 40px;
		z-index: 999;
	}
	.sjd {
		width: 126px;
		height: 44px;
	}
	.sjd:hover + .erweima-list {
		display: flex;
	}
	.erweima-list {
		display: none;
		background: #fff;
		padding: 24px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		position: absolute;
		right: 0;
		top: 80px;
	}
	.erweima-item {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.erweima-item:nth-child(1) {
		margin-right: 36px;
	}
	.erweima-item img {
		width: 180px;
		height: 180px;
		margin-bottom: 16px;
	}
	.erweima-item p {
		font-size: 20px;
		font-family: .PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #001A18;
	}
</style>

